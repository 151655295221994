body {
  margin: 0;
  padding: 0;
  /*  font-family: InterstateLight;
  font-weight: normal;*/
  font-family: "Overpass",Tahoma,Arial,sans-serif;
  font-weight: 400;
  font-size: 0.9rem;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  font-family: InterstateCondensedBold;
  /* font-family: Overpass; */  
  font-weight: normal;
}

.ft-screen {
  max-width: 980px;
}

#headerstatusNew {
  position: absolute;
  height: 40px;
  right: 20px;
  bottom: 20px;
  overflow: hidden;
}

#tippspiel span.tournamentSelectionComponent button {
  /*font-family: InterstateCondensed; letter-spacing: 0.07px;*/
  color: #ee7203;
  letter-spacing: -0.05em;
  text-decoration: none;
}

#tippspiel span.tournamentSelectionComponent div button {
/*font-family: InterstateCondensed; letter-spacing: 0.07px;*/
  font-size: 1rem;
  color: #ee7203;
  letter-spacing: -0.05em;
}

.table thead th {
  /*font-family: InterstateLight;*/
  font-size: 0.75rem;
  color: #000000;
  letter-spacing: 0;
}

.table {
  max-width: 980rem;
}

.ft-team-link, .ft-team-link-sm, .ft-team-link-lg {
  max-width: 85px;
  width: auto;
  letter-spacing: 0.06px;
  padding-top: 0.25rem
}
.ft-team-link-sm, .ft-team-link-lg {
  max-width: 180px;
}

#tippspiel .btn-r1gray {
  background-color: #F2F2F2;
  border: 1px solid #CECECE;
  border-radius: 4px;
  color: black;
}

.btn-link {
  color: #02B401;
}

a {
  color: #02B401;
  text-decoration: none;
}

.btn-r1--gameresult {
  /*font-family: InterstateCondensed;*/
  letter-spacing: -0.06px;
  color: #FFFFFF;
  text-align: center;
  background: #3C3C3C;
  border-radius: 4px;
  padding: 0.25rem 0.25rem;
  display: inline-block;
  min-width: 35px;
}

.btn-r1--bet {
  font-family: InterstateCondensedBold;
  color: #FFFFFF;
  text-align: center;
  background: #46C746;
  border-radius: 4px;
  padding: 0.25rem 0.25rem;
  display: inline-block;
  min-width: 35px;
}

.btn-r1--bet-input {
  font-family: InterstateCondensedBold;
  font-size: 0.9rem;
  color: #FFFFFF;
  text-align: center;
  background: #E2FCE2;
  box-shadow: inset 1px 1px 4px 0 rgba(8,94,8,0.80);
  border-radius: 4px;
  padding: 0.05rem 0.05rem;
  display: inline-block;
  min-width: 33px;
  min-height: 30px;
}

.btn-r1--bet-input Input {
  margin-top: 4px;
  background-color: #E2FCE2;
  width: 14px;
  max-height: 22px;
}

.btn-r1--points {
  /*font-family: InterstateCondensed;*/
  letter-spacing: -0.06px;
  color: #000000;
  text-align: center;
  background-image: linear-gradient(180deg, #FFFFFF 30%, #F2F2F2 100%);
  border: 1px solid #CECECE;
  border-radius: 4px;
  padding: 0.25rem 0.45rem;
  display: inline-block;
  min-width: 28px;
}

.ft-game-teams {
  max-width: 180px;
}

.img-r1--profile-image {
  max-width: 304px;
}

@media (min-width: 420px) {
  .ft-game-teams {
    max-width: 240px;
  }
  .img-r1--profile-image {
    max-width: 380px;
  }
  }

@media (min-width: 576px) {
  .ft-game-teams {
    max-width: 320px;
  }
}
@media (min-width: 996px) {
  .ft-game-teams {
    max-width: 420px;
  }
}
.bg--r1-menu {
    background: url(https://static2.funtip.de/static-img/r1/bg_logo_small.png) no-repeat; 
    background-size: cover;
}

.border-black {
  border-color: #000000 !important;
}

.btn-r1-menu-active {
  border-left: #ee7203 solid 3px !important;
}

@media (max-width: 575px) {
  .bg--r1-menu-collapse {
    background-color: #017C00;
  }
}

@media only screen and (max-width: 480px) { 
  #headerstatusNew {
    display: none;
  }
}

@media only screen and (max-width: 768px) { 
  body {
    font-size: 0.8rem;
  }
  .btn {
    font-size: 0.9rem;
  }
}

.container-fluid {
  max-width: 980px;
}

#header .nav { display: block }

#header .nav .nav-meta-header, #containerMainFlyoutThemen, div.doctypeteaser {
  box-sizing: content-box;
}

#responsiveNavigation {
  background-color: rgb(44, 45, 47);
}

#nav-meta-container > ul.nav-meta-list {
  margin-bottom: 0;
}

html {
  overflow-y:scroll;
}

body#tippspiel {
  background: url(https://static2.funtip.de/static-img/r1/bg.jpg) repeat;
}

#containerRbb_subHead {
  background-color: white;
}

@media (min-width: 1200px) {
  .container {
    max-width: 980px !important;
  }
}

#socialmediabuttons *,
    *:before,
    *:after {
      -webkit-box-sizing: content-box;
      -moz-box-sizing: content-box;
      box-sizing: content-box;
}

div.blogging-app--blog-entry,
div.blogging-app--blog-entry p:first-child {
  display: inline;
}
#navigationmain {
  margin-top: -16px;
}

div.r1-outer-padding {
  margin-top: -8px;
}

div#livestream p {
  margin-bottom: 0px;
}

@media only screen and (min-width: 980px) {
  .r1-outerpadding {
    padding-left:7px; padding-right:7px
  }
}
@media only screen and (max-width: 980px) {
  #containerRbb_subHead {
    margin-left:0px;
  }
}
@media only screen and (max-width: 480px) {
  #searchbox {
    height: 55px;
  }
  *, *::before, *::after {
  /* box-sizing: content-box;  */
  }
  #responsiveNavigation {
    background-color: white;
  }  
  #navigationmain {
    margin-top: 0px;
  }
  #r1--comment-rss-portal .row {
    margin-right: 0px;
  }


}
