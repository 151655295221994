// Override default variables before the import
// $body-bg: #000;

$primary: #02B401;
$secondary: #f2f2f2;
$warning: #ee7203;
/*
$theme-colors: (
  "primary": #02B401,
  "warning": #ee7203,
  "secondary": #f2f2f2,
);
*/
$mark-bg: #ee7203;

// The yiq lightness value that determines when the lightness of color changes from "dark" to "light". Acceptable values are between 0 and 255.
$yiq-contrasted-threshold:  160 !default;
$min-contrast-ratio: 2.9 !default;

// Import Bootstrap and its default variables
// @import '~bootstrap/scss/bootstrap.scss';
@import "~bootstrap/scss/bootstrap";

@include media-breakpoint-up(sm) {
  html {
/*    font-size: 0.33rem; */
  }
}

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .border#{$infix}-top {      border-top: $border-width solid $border-color ; }
    .border#{$infix}-right {    border-right: $border-width solid $border-color ; }
    .border#{$infix}-bottom {   border-bottom: $border-width solid $border-color ; }
    .border#{$infix}-left {     border-left: $border-width solid $border-color ; }

    .border#{$infix}-top-0 {    border-top: 0 !important; }
    .border#{$infix}-right-0 {  border-right: 0 !important; }
    .border#{$infix}-bottom-0 { border-bottom: 0 !important; }
    .border#{$infix}-left-0 {   border-left: 0 !important; }

    .border#{$infix}-x {
      border-left: $border-width solid $border-color ;
      border-right: $border-width solid $border-color ;
    }

    .border#{$infix}-y {
      border-top: $border-width solid $border-color ;
      border-bottom: $border-width solid $border-color ;
    }
  }
}